footer{
    background-color: black;
    border: 1px solid #FFF;
    
    display: flex;
    position: relative;
    z-index: 1;
    overflow-x: hidden;
    justify-content: center;
    
    color:#FFF;
    font-family: "Electrolize", sans-serif;
}