

header{
    background-color: black;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    
}

.header_static{
    display: flex;
}
.nav_item{
    cursor: pointer;
    user-select: none;
    margin:2vw;
}

.nav_item:hover{
    color:red;
}

.navigator{
    padding: 1vw;
    border: 1px solid #FFF;
    border-top: 0px;
}

.title{
    flex: 1;
    display: flex;
    align-items: center;
    border: 1px solid #FFF;
    color: #FFF;
    padding-left: 1vw;
}

.item{
    display: flex;
    align-items: center;
    color: #FFF;
    border: 1px solid #FFF;
    border-left: #000;
    padding:.75vw;
    cursor: pointer;
    user-select: none;
    text-decoration: none;
}
.item_dropped{
    display: flex;
    align-items: center;
    color: #FFF;
    border: 1px solid #FFF;
    border-left: #000;
    padding:.75vw;
    cursor: pointer;
    user-select: none;
    border-bottom: 0px;
}

.icon{
    width: clamp(1.5vh,1vw,5vh);
    padding: 1vw;
    border: 1px solid #FFF;
    border-right: #000;
    cursor: pointer;
}
.icon_dropped{
    width: clamp(1.5vh,1vw,5vh);;
    padding: 1vw;
    border: 1px solid #FFF;
    border-right: #000;
    border-bottom: #000;
    cursor: pointer;
}