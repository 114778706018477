.collision_rect{
    background-color: black;
    color:white;
    max-width:100vw;
    height: 100vh;
    overflow-x: hidden;
    padding: 3vw 5vw 3vw !important;
    box-sizing: border-box;
   
    
}

.collision_rect > main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    border: 1px solid white;
}

.collision_rect .text_section{
    padding: 0vh 15vw 2vh;
    line-height: 3vh;
}
.collision_rect .header{
    text-align: center;
}
.collision_rect .subheader{
    text-align: center;
    font-size: 1.5vw;
}

.game{
    width: 80vw;
    height:40vw;
    margin-bottom: 10vh;
}