.title{
    font-size: 2.5vw;
    color:white;
    text-wrap: nowrap;
    font-family: "Electrolize", sans-serif;
}
.extra_large{
    color: #FFF;
    font-family: "Electrolize", sans-serif;
    font-size: 5vw;
}
.large{
    color: #FFF;
    font-family: "Electrolize", sans-serif;
    font-size: 1.5vw;
}
.header{
    color: #FFF;
    font-family: "Electrolize", sans-serif;
    font-size: 2vw;
    font-weight: bold;
}
.subheader{
    color: #FFF;
    font-family: "Electrolize", sans-serif;
    font-size: 2vw;
}
.plain{
    color: #FFF;
    font-family: "Electrolize", sans-serif;
    font-size: 1vw;
}

/** grid & subgrid media adjustments **/
@media (max-width: 800px) {
    .title{
        font-size: 4vw;
        color:white;
        text-wrap: nowrap;
        font-family: "Electrolize", sans-serif;
    }
    .extra_large{
        color: #FFF;
        font-family: "Electrolize", sans-serif;
        font-size: 6vh;
        text-align: center;
    }
    .large{
        color: #FFF;
        font-family: "Electrolize", sans-serif;
        font-size: 2vh;
        text-align: center;
    }
    .header{
        color: #FFF;
        font-family: "Electrolize", sans-serif;
        font-size: 3vh;
        text-align: center;
        font-weight: bold;
    }
    .subheader{
        color: #FFF;
        font-family: "Electrolize", sans-serif;
        font-size: 3vw;
    }
    .plain{
        color: #FFF;
        font-family: "Electrolize", sans-serif;
        font-size: 1.5vh;
    }
}