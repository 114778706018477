.spacer{
    height: 400vh;
    
}

main{
    padding-top: 14vh;
    font-family:'Courier New', Courier, monospace;
}

.abs{
    padding: 3vw;
    padding-left: 5vw;
    padding-right: 5vw;
}

.abs section{
    margin-top:10vh;
    background-color:#00000055 !important;
    padding-left:2vw;
    padding-right:2vw;
    padding-top:1vw;
    padding-bottom: 1vw;
}

.abs h2{
    text-align: center;
}
.abs h1{
    text-align: center;
}
p{
    font-size: 1vw;
}
ol{
    font-size: 1vw;
}

.indent{
    text-indent: 30px;
}
.equation{
    color:#FFF;
}

@media (max-width: 800px) {
    h2{
        font-size: 3vw;
    }
    p{
        font-size: 2vw;
    }
    ol{
        font-size: 2vw;
    }
    
    .indent{
        text-indent: 30px;
    }
    .title{
        font-size: 5vw;
        text-align: center;
    }
    .subtitle{
        font-size: 2vw;
        text-align: center;
    }
}