

main{
    display: inline-block;
    width:100%;
    padding:0 !important; /* Aligns Section to bottom of header*/
}

.page{
    display: block;
    padding: 3vw;
    padding-left: 5vw;
    padding-right: 5vw;
}

.home section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    overflow: hidden;
    padding: 1vw;

    border: 1px solid #FFF;
    background-color: #000000;
    color: #FFFFFF; 
    
}
.home .padded{
    padding-left: 3vw;
    padding-right: 3vw;
    padding-top:2vh;
    padding-bottom:2vh;
}

.left_align{
    display: flex;
    flex-direction: column;
    align-items: left;
}

.home .headshot{
    max-width: 15vw;
    height: auto;
    vertical-align: middle;
    display: inline-block;
    overflow: hidden;
}
/** subgrid styling*/
.grid-wrapper > .subgrid{
    display: grid;
    grid-gap: 0;
    grid-template-columns: auto auto;
    grid-auto-rows:auto;
    grid-auto-flow: dense; 
}
.subgrid > .single{
    display: flex;
    flex-direction: column;
    grid-column: span 1;
    overflow:  hidden ;
    justify-content: center;

    width: 100%;
    flex-grow: 1; /* Allow it to grow in flex layouts */
}
.subgrid > .canvas_wrapper{
    margin: 0;
    padding: 0;
    border: none;
    box-sizing: border-box;
    overflow: hidden;
}

/** grid styling **/

.home .grid-wrapper{
    display: grid;
    grid-gap: 0;
    grid-template-columns: auto auto auto auto;
    grid-auto-rows:auto;
    grid-auto-flow: dense;
}

.grid-wrapper .single{
    grid-column: span 1;
}
.grid-wrapper .double{
    grid-column: span 2;
    overflow: hidden;
}
.grid-wrapper .wide{
    grid-column: span 3;
}
.grid-wrapper .full{
    grid-column: span 4;
}

.grid-wrapper .tall{
    grid-row: span 2;
}

.gapped{
    gap:2vh;
}

/** grid & subgrid media adjustments **/
@media (max-width: 800px) {
    /** subgrid styling*/
    .grid-wrapper > .subgrid{
        display: grid;
        grid-gap: 0;
        grid-template-columns: auto;
        grid-auto-rows:auto;
        grid-auto-flow: dense; 
    }
    .home .grid-wrapper{
    display: grid;
    grid-gap: 0;
    grid-template-columns: auto;
    grid-auto-rows:auto;
    grid-auto-flow: dense;
    }
    .grid-wrapper .single{
        grid-column: span 1;
    }
    .grid-wrapper .double{
        grid-column: span 1;
        overflow: hidden;
    }
    .grid-wrapper .wide{
        grid-column: span 1;
    }
    .grid-wrapper .full{
        grid-column: span 1;
    }
    
    .grid-wrapper .tall{
        grid-row: span 1;
    }
}

/** Sidescrolling text animation **/
@keyframes infiniteScrollLeft {
    from {transform: translateX(0) }
    to {transform: translateX(calc(0px - 50%));}
}
@keyframes infiniteScrollRight {
    from {transform: translateX(0) }
    to {transform: translateX(calc(0px + 50%));}
}
@keyframes infiniteScrollDown {
    /* from {transform: translateY(0) }
    to {transform: translateY(calc(0px - 100%));} */
    0%{transform: translateY(0) }
    33%{transform: translateY(calc(0px - 33%)); }
    66%{transform: translateY(calc(0px - 66%)); }
    100%{transform: translateY(calc(0px - 100%)); }
}


.horizontal-scrolling-text-left {
    display: inline-block;
    animation-name: infiniteScrollLeft;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    white-space: nowrap;
}
.horizontal-scrolling-text-right {
    display: inline-block;
    animation-name: infiniteScrollRight;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    white-space: nowrap;
}
.horizontal-scroll-container{
    display: inline-block;
    white-space: nowrap;
}

@keyframes text-pop-up-top {
    0% {
      transform: translateY(0);
      transform-origin: 50% 50%;
      text-shadow: none;
    }
    25% {
      transform: translateY(-10%);
      transform-origin: 10% 10%;
      text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
    }
    50% {
        transform: translateY(0);
        transform-origin: 50% 50%;
        text-shadow: none;
      }
    100% {
        transform: translateY(0);
        transform-origin: 50% 50%;
        text-shadow: none;
      }
}

.popup_word{
    display: inline-block;
    padding:1vw;
    animation: text-pop-up-top 3s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite;
}

.pop1{
    animation-delay: 0s;
}
.pop2{
    animation-delay: 1s;
}
.pop3{
    animation-delay: 2s;
}

.popup_word_container{
    display: flex;

}
